@import 'utils.scss';
@import 'breakpoints.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 320 px;
  height: 296px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  gap: 10px;

  @include to(1) {
    width: 253px;
    height: 235px;
    padding: 15px;
    overflow: hidden;
  }

  p {
    color: var(--color-gray-400);
    line-height: 1.4;
  }
}

.nameContainer {
  display: flex;
  align-items: center;
  height: 46px;
  gap: 8px;

  @include to(1) {
    height: 36px;
  }
}

.image {
  width: 46px;
  height: 46px;
  object-fit: contain;

  @include to(1) {
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;
  }
}

.name {
  font-size: 17px;
  font-weight: 700;

  @include to(1) {
    font-size: 13px;
  }
}

.quote {
  margin: 0;
  overflow-y: auto;
  font-size: 17px;
  font-weight: 400;

  @include to(1) {
    font-size: 14px;
  }
}
