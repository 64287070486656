@use 'utils.scss';
@use 'breakpoints.scss';
@use '../theme/mixins.scss';

.ways {
  display: flex;
  gap: 20px;
  flex-direction: row;

  @include breakpoints.from(4) {
    justify-content: space-between;
  }

  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.container.container {
  display: flex;
  position: relative;
  z-index: 5;
  flex: 0 1 1060px;
  flex-direction: column;
  align-items: center;
  max-width: 1060px;
  background-color: #ffffff;
  gap: 22px;

  @include breakpoints.to(1) {
    padding: 90px 20px 20px;
    gap: 10px;
  }

  @include breakpoints.from(2) {
    padding: 100px 0 55px;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    bottom: -150px;
    width: 100%;
    min-width: 100vw;
    height: 240px;
    background: url('../theme/assets/CloudTileBottom.svg');
    background-repeat: repeat-x;
    background-position: top center;
    background-size: 1440px; // might need to tweak this
    pointer-events: none; // don't block clicks

    @include breakpoints.to(1) {
      bottom: -183px;
      background-size: 650px;
      background-position-x: calc(50% - 45px);
    }
  }

  > * {
    // NOTE: Fixes IE11 bug.
    // See https://github.com/philipwalton/flexbugs#flexbug-2

    max-width: 100%;
  }
}

.title {
  @include mixins.gradientText;

  margin: 0;
  font-weight: 800;

  @include breakpoints.to(1) {
    // margin-bottom: 10px;
    font-size: 32px;
  }

  @include breakpoints.from(2) {
    font-size: 62px;
  }
}

.content {
  max-width: 45ch;
  margin: 0;
  color: var(--color-text-alternate);
  font-size: 24px;
  line-height: 34px;
  text-align: center;

  @include breakpoints.to(1) {
    width: 335px;
    margin-bottom: 30px;

    p {
      font-size: 18px;
    }
  }

  @include breakpoints.from(2) {
    margin-bottom: 25px;
    white-space: pre-line;
  }

  p {
    margin: 0;
  }
}

.diagram {
  @include breakpoints.to(1) {
    width: 300px;
    margin-bottom: 45px;
  }

  @include breakpoints.from(2) {
    max-width: 500px;
    margin-bottom: 150px;
  }
}

.exit {
  @include utils.markdownRemoveMargins;

  text-align: center;

  @include breakpoints.to(1) {
    margin: 30px 0;
    font-size: 28px;
    white-space: pre-line;
  }

  @include breakpoints.from(2) {
    margin: 30px 0;
    font-size: 38px;
  }
}
