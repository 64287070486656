@mixin body2 {
  font-size: var(--font-size-body-2, 22px);
  font-weight: var(--font-weight-body-2, 400);
  line-height: var(--line-height-body-2, 140%);
}

@mixin gradientText {
  color: var(--color-gradient-primary, #798dd3);

  @supports (background-clip: text) {
    background-clip: text;
    background-image: linear-gradient(
      93.3deg,
      var(--color-gradient-primary, #798dd3) 11.67%,
      var(--color-gradient-secondary, #a763cb) 83.78%
    );
    background-repeat: repeat;
    background-size: 100%;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}
